<template>
  <v-container tag="section" fluid>
    <v-row 
      class="mx-auto mb-10" v-for="category in categorias" 
      :key="category.title"
    >
      <template>
        <v-col cols="12">
          <h2 id="categoria-relatorios">
            {{ category.title }}
          </h2>
        </v-col>
        <v-col 
          v-for="powerup in category.powerups" 
          :key="powerup.nome"
          xs="12" 
          sm="6" 
          md="4" 
          xl="3"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  :class="!powerup.ativo ? 'disabled-card' : ''"
                  :style="hover && !powerup.ativo ? 'cursor: not-allowed' : ''"
                  id="card-powerup"
                  class="mx-2 d-flex justify-center"
                  min-width="200px"
                  height="100%"
                  raised
                  :color="
                    hover && powerup.ativo ? 'grey lighten-2' : 'grey lighten-3'
                  "
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                  :to="powerup.route"
                >
                  <v-row no-gutters align="center" justify="center">
                    <v-col cols="2">
                      <v-icon
                        class="my-auto d-flex justify-center"
                        :size="40"
                        color="primary"
                      >
                        {{ powerup.icon }}
                      </v-icon>
                    </v-col>
                    <v-col cols="10">
                      <v-card-text class="mx-auto my-auto">
                        <div class="subtitle-2" style="word-break: normal">
                          {{ powerup.nome }}
                        </div>
                        <div class="caption text--secondary">
                          {{ powerup.descricao }}
                        </div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </v-hover>
            </template>
            {{ powerup.nome }}
          </v-tooltip>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
  
<script>

export default {
  data: () => ({
    categorias: [
      {
        title: "",
        powerups: [
          {
            nome: "Exportações do INDGER",
            descricao: "Exporta dados do INDGER importados no sistema",
            icon: "mdi-export",
            route: "/dados/exportacoes-indger/historico",
            ativo: true,
          },
          {
            nome: "Consulta Aprovações de Temas",
            descricao: "Consulta indicadores aprovados e não aprovados por tema do INDGER",
            icon: "mdi-clipboard-outline",
            route: "/dados/consulta-aprovacoes-temas",
            ativo: true,
          },
          // {
          //   nome: "Manutenção AL/SE/LD",
          //   descricao: "Cadastra e acompanha dados das bases técnicas do INDGER",
          //   icon: "mdi-format-list-group-plus",
          //   route: "/dados/manutencao-tecnicos",
          //   ativo: true,
          // },
        ]
      },
    ],
  })
};

</script>